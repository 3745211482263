import React from "react";

import Layout from "layouts/Layout";
import Skills from "../components/Skills";

const SkillsPage = () => {
  return (
    <Layout seoTitle="Skills">
      <Skills />
    </Layout>
  );
};

export default SkillsPage;
