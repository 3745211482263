import React, { useEffect, useContext } from "react";
import { gsap, Power4, Expo } from "gsap";

import Button from "elements/Button";

import "./style/skills.scss";
import skills from "./services/skills.json";
import { AppContext } from "../../context/AppContext";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Skills = () => {
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    let skillCardTimeline = gsap.timeline();
    let pageContentTimeLine = gsap.timeline();

    skillCardTimeline.from(".skill-card", {
      duration: 0.5,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      stagger: 0.2,
      opacity: 0,
      y: 20,
    });

    pageContentTimeLine.from(".animation-up", {
      duration: 0.5,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      opacity: 0,
      y: 20,
    });

    //eslint-disable-next-line
  }, []);

  const handleShowContactForm = () => {
    dispatch({ type: "TOGGLE_CONTACT_ME" });

    let contactFormTimeline = gsap.timeline();
    let contactFormBackgroundTimeline = gsap.timeline();

    contactFormTimeline.to(".contact-me-form-container", {
      duration: 0.5,
      height: "auto",
      ease: Power4.easeOut,
    });

    contactFormTimeline.from(".contact-me-form-content", {
      ease: Power4.easeOut,
      duration: 0.5,
      opacity: 0,
      y: 20,
    });

    contactFormBackgroundTimeline.to(".contact-me-container", {
      ease: Expo.easeOut,
      duration: 1,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    });
  };

  return (
    <div className="skills-page-container">
      <div className="columns is-desktop">
        <div className="column animation-up">
          <h1 className="title">Skills & Expertise</h1>
          <p>
            My main area of expertise is in developing fast, responsive, and
            easy to use website applications. I can work as a Full Stack
            Developer handling both front-end and back-end side of the
            development process. I use GatsbyJS and NextJS, a React-based
            framework as my main front-end framework and Python Django or Flask
            as my back-end framework, but I am also flexible in working Firebase
            as back-end frameworks.
          </p>
          <p>
            Being a front-end developer also taught me how to design websites
            and application as well as work with graphic UI/UX design tools such
            as Adobe Xd, Photoshop, and Illustrator.
          </p>
          <p>
            As a Computer Engineering graduate, I can also work with the
            hardware side of technology such as Arduino and Raspberry Pi, along
            with motors and sensors to create robotics, automation, and/or
            Internet of Things architecture.
          </p>
          <p>
            <Button
              handleClick={handleShowContactForm}
              className="skill-cta-button"
              color="primary"
            >
              Contact Me
              <span className="ml-3">
                <FontAwesomeIcon icon={faPhone} />
              </span>
            </Button>
            <p className="is-size-7">
              Want to work with me? Send me a message!
            </p>
          </p>
        </div>
        <div className="column cards-container">
          <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
            {skills?.map((skill) => (
              <div className="card skill-card has-text-centered">
                <div className="card-content">
                  <div className="content">
                    <h3 className="title">{skill?.label}</h3>
                    <div className="content">
                      {skill?.badges?.map((badge) => (
                        <img
                          src={badge}
                          alt="skill badge"
                          className="skill-badge has-tooltip-bottom"
                          data-tooltip="Tooltip Text"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
